
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiServiceGet, apiServiceSet } from '@/api/setting'
import materialSelect from '@/components/material-select/index.vue'

@Component({
  components: {
    materialSelect
  }
})
export default class ListsDetail extends Vue {
  /** S Data **/

  // 添加商城表单数据
  form: any = {
    service_name: '', // 客服名称
    service_mobile: '', // 客服电话
    service_qrcode: '' // 二维码
  };

  // 表单校验
  rules = {
    service_name: [{ required: true, message: '请输入客服名称', trigger: 'blur' }],
    service_mobile: [{ required: true, message: '请输入客服电话', trigger: 'blur' }],
    service_qrcode: [
      { required: true, message: '请选择客服二维码', trigger: 'blur' }
    ]
  };

  /** E Data **/

  /** S Methods **/
  // 点击表单提交
  onSubmit (formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate((valid: boolean): any => {
      if (!valid) return
      this.handleEdit()
    })
  }

  async handleEdit () {
    await apiServiceSet({ ...this.form })
    this.getShopDetailFunc()
  }

  // 获取详情
  async getShopDetailFunc (): Promise<void> {
    const res: any = await apiServiceGet()
    Object.keys(res).map((key) => {
      this.$set(this.form, key, res[key])
    })
  }
  /** E Methods **/

  /** S Life Cycle **/
  created () {
    this.getShopDetailFunc()
  }
  /** E Life Cycle **/
}
